import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import moment from 'moment';

export default function Terms() {
    const currentYear = moment().year();

    return (
        <>
            <Helmet>
                <title>Podmienky poskytovania služieb a licenčná zmluva- Feudarium - Webová hra</title>
            </Helmet>
            <Layout>
                <h2>Podmienky poskytovania služieb a licenčná zmluva</h2>

                <h3>Licenčná zmluva</h3>

                <div class="row">
                    <div class="col-xs-12">
                        <p>
                            Tieto podmienky poskytovania služieb a licenčná zmluva Koncového užívateľa tvoria úplnú
                            dohodu medzi Vami ako Koncovým užívateľom a firmou Bc. Milan Herda Perungrad, Kpt. Jána Rašu
                            3147/21, 841 01 Bratislava-Dúbravka, Slovenská republika (ďalej len "Perungrad"). Táto
                            licenčná zmluva upravuje používanie poskytovaného softvéru s názvom "Feudarium" (ďalej len
                            "Feudarium") Koncovým užívateľom. Registráciou súhlasíte s podmienkami tejto licencie.
                            Pokiaľ s podmienkami nesúhlasíte, nemôžete Feudarium používať.
                        </p>
                        <p>
                            Feudarium je je chránené autorským zákonom Slovenskej republiky a medzinárodnými zmluvami a
                            konvenciami o ochrane autorských práv a ďalšími zákonmi. Feudarium je poskytovaný v licencii
                            a táto zmluva Vám nepriznáva žiadne nároky na vlastníctvo Feudaria ani žiadnej z jeho kópií.
                            Akékoľvek použitie Feudaria podlieha podmienkam licencie uvedenými nižšie. Použitie
                            Feudaria, jeho častí, reprodukcia alebo redistribúcia, ktoré nie sú v súlade s podmienkami
                            licencie, sú zakázané.
                        </p>

                        <h4>Rozsah licencie</h4>

                        <p>
                            Perungrad Vám ako Koncovému používateľovi udeľuje právo používať Feudarium pre svoje osobné
                            účely. Toto právo sa udeľuje jednotlivcovi, je neprenosné, nevýhradné, odmedzené a
                            odvolateľné. Koncový používateľ môže Feudarium používať na zariadení, ktoré vlastní alebo
                            ktoré kontroluje a prostredníctvom ktorého má Koncový používateľ prístup k Feudariu pomocou
                            internetového prehliadača. Právo používať Feudarium sa poskytuje bezplatne.
                        </p>

                        <h5>Nie je dovolené</h5>

                        <ul>
                            <li>upravovať Feudarium s výnimkou v rozsahu povolenom záväzným právom</li>
                            <li>
                                poskytnúť sublicenciu k Feudariu alebo sprístupniť Feudarium tretím osobám nájmom,
                                vytvorením softvérovej služby (Software-as-a-Service) alebo inak
                            </li>
                            <li>
                                odstrániť alebo znečitateľniť oznámenia o firme Perungrad ako o držiteľovi autorských
                                práv k Feudariu
                            </li>
                        </ul>

                        <h5>Ďalej nie je dovolené</h5>

                        <ul>
                            <li>
                                Zakladať hromadné účty. Každý Koncový používateľ môže mať vytvorený maximálne jeden účet
                                pre herný svet.
                            </li>
                            <li>Prihlasovať sa na cudzie účty.</li>
                            <li>Správať sa vulgárne voči iným používateľom a urážať iných používateľov.</li>
                            <li>Obťažovať alebo ohovárať iných používateľov.</li>
                            <li>
                                Propagovať násilie, fašizmus, rasizmus alebo iné ideológie alebo hnutia, ktoré potláčajú
                                ľudské práva a slobody akoukoľvek formou a ktoré sú v rozpore s legislatívou Slovenskej
                                republiky.
                            </li>
                            <li>
                                Získať (alebo pokúsiť sa získať) heslá alebo iné súkromné informácie od iných
                                používateľov.
                            </li>
                            <li>
                                Používať softvér pre automatizáciu, počítačové pirátstvo alebo podvody pre získanie
                                výhody vo Feudariu alebo úpravu Feudaria
                            </li>
                            <li>
                                Prostredníctvom používateľského obsahu šíriť počítačové vírusy, adware, spyware alebo
                                iný škodlivý alebo nezákonný softvér, či zdrojový kód.
                            </li>
                        </ul>

                        <h5>Oprávnenia administrátorov a Perungradu</h5>

                        <p>
                            Administrátori Feudaria, firma Perungrad alebo iné ňou poverené osoby majú v prípade
                            porušenia podmienok licencie používateľom nasledovné oprávnenia: môžu zmazať správy tohto
                            používateľa; môžu mu zneprístupniť Feudarium dočasne alebo trvalo; môžu mu zrušiť
                            používateľský účet. Používateľ v takomto prípade nemá nárok na náhradu žiadnej škody.
                        </p>

                        <h4>Vlastníctvo</h4>

                        <p>
                            Všetky práva na duševné vlastníctvo v a k Feudariu (vrátane, ale nielen na obraz a ďalší
                            obsah) a právny nárok na všetky kópie vlastní Perungrad ako Poskytovateľ licencie alebo jeho
                            zmluvní partneri a Koncový používateľ nezískáva žiadne právo ani podiel iný, než je obsahom
                            tejto licencie.
                        </p>

                        <h4> Zrieknutie sa záruk a obmedzenie zodpovednosti</h4>

                        <p>
                            Koncový užívateľ berie na vedomie a súhlasí s tým, že používanie Feudaria je na jeho vlastné
                            riziko. Perungrad nenesie žiadnu zodpovednosť za stratu, odcudzenie alebo uhádnutie
                            prihlasovacích údajov Koncového používateľa. Feudarium je poskytované v stave "ako je dané"
                            a "ako je k dispozícii" bez záruk akéhokoľvek druhu a Perungrad nie je povinný zriaďovať
                            náhradu škôd. Perungrad nezaručuje vhodnosť informácií obsiahnutých v dokumentoch ani
                            súvisiacich vyobrazeniach publikovaných na Feudariu pre akýkoľvek účel.
                        </p>

                        <p>
                            Perungrad negarantuje dostupnosť serveru Feudaria a nemennosť Feudaria v žiadnom rozsahu.
                            Perungrad nenesie voči Koncovému používateľovi žiadnu zodpovednosť, pokiaľ zmení alebo zruší
                            akúkoľvek časť Feudaria a to vrátane úplného ukončenia poskytovania služieb Feudaria.
                        </p>

                        <h4>Trvanie a ukončenie dohody</h4>

                        <p>
                            Táto licencia nadobúda účinnosť začiatkom používania Feudaria Koncovým používaľom a končí
                            ukončením používania Feudaria zo strany Koncového používateľa. Takisto končí zamedzením
                            prístupu Koncovému používateľovi k Feudariu, pokiaľ používateľ porušil licenčné podmienky.
                            Taktiež končí ukončením poskytovania služieb Feudaria zo strany Perungrad.
                        </p>

                        <h4>Autorské práva</h4>
                        <p>
                            Copyright &copy; 2015 - {currentYear} Bc. Milan Herda Perungrad, Kpt. Jána Rašu 3147/21, 841
                            01 Bratislava-Dúbravka, Slovenská republika. Všetky práva vyhradené.
                        </p>
                    </div>
                </div>

                <h3 id="cookies">Používanie cookies</h3>

                <p>
                    Súbory cookie sú malé súbory uchovávané v zariadení Koncového používateľa, ktoré obsahujú neosobné
                    údaje. Feudarium používa súbory cookie na nasledovné účely:
                </p>

                <ul>
                    <li>
                        Prenášanie informácie potrebnej k overeniu toho, či je používateľ prihlásený (tzv. Session ID)
                    </li>
                    <li>Zabezpečenie automatického prihlasovania používateľa</li>
                    <li>Súhlas s používaním cookies</li>
                    <li>
                        Cookie tretích strán, ktoré slúžia na štatistické účely (Google Analytics, Google Tag Manager)
                    </li>
                </ul>

                <hr />

                <h3>Využívanie niektorých údajov</h3>

                <p>
                    Koncový používateľ môže mať vo svojom konte uložené údaje ako: emailová adresa, mesiac a rok
                    narodenia. Tieto údaje sa nezobrazujú žiadnym iným používateľom Feudaria a sú k dispozícii iba
                    používateľovi a preváďzkovateľovi Feudaria. Prevádzkovateľ môže tieto údaje využívať na nasledovné
                    účely:
                </p>

                <h4>Emailová adresa</h4>

                <p>Emailová adresa sa v tejto chvíli nepoužíva žiadnym spôsobom. Budúce použitie môže zahŕňať:</p>

                <ul>
                    <li>Reset používateľského hesla</li>
                    <li>Odosielanie informácií k Feudariu (upozornenia a informácie o zmenách, výpadkoch a pod.)</li>
                    <li>
                        Odosielanie informácií k dianiu vo Feudariu (upozornenia na prijatú správu a/alebo na rôzne
                        herné udalosti)
                    </li>
                    <li>Marketingové kampane vzťahujúce sa k Feudariu</li>
                    <li>Marketingové kampane vzťahujúce sa k iným službám poskytovaných prevádzkovateľom Feudaria</li>
                </ul>

                <p>Okrem resetu používateľského hesla bude možné sa odhlásiť z prijímania každého druhu emailov.</p>

                <h4>Mesiac a rok narodenia</h4>

                <p>
                    Tento údaj používame iba na štatistické účely ako napríklad zisťovanie vekového rozloženia
                    používateľov Feudaria a prípadného rozloženia úspešnosti marketingovej kampane v závislosti od veku
                    používateľa. Používateľ nie je povinný tento údaj vyplniť a v prípade, že sa rozhodne ho vyplniť,
                    nie je viazaný tým, aby bol údaj pravdivý.
                </p>
            </Layout>
        </>
    );
}
